<template>
  <div class="date-banner">
    <Tooltip v-if="hasPrev" :text="translations.datetime.monthBanner.prev" class="date-banner__prev">
      <span
        class="date-banner__prev-btn"
        tabindex="0"
        @click="$emit('update', viewDate.add(-1, type))"
        @keydown.enter="$emit('update', viewDate.add(-1, type))"
      />
    </Tooltip>
    <div
      v-if="isPrevCurrent || isNextCurrent"
      class="date-banner__go-to-current"
      v-class-mod:date-banner__go-to-current="{
        isPrevCurrent,
        isNextCurrent,
      }"
    >
      <icon-toggle-button
        :icon="getStepIcon"
        :tooltip="{ title: translations.datetime.monthBanner.current }"
        :action="goToCurrent"
        button-size="medium"
        tabindex="0"
      />
    </div>
    <span class="date-banner__title">
      <span
        v-if="hasTitleAction"
        class="date-banner__link"
        tabindex="0"
        @click="(e) => emit('click:title', e)"
        @keydown.enter="(e) => emit('click:title', e)"
      >
        {{ title }}
      </span>
      <template v-else>{{ title }}</template>
    </span>
    <Tooltip v-if="hasNext" :text="translations.datetime.monthBanner.next" class="date-banner__next">
      <span
        class="date-banner__next-btn"
        tabindex="0"
        @click="$emit('update', viewDate.add(1, type))"
        @keydown.enter="$emit('update', viewDate.add(1, type))"
      />
    </Tooltip>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import { IconName } from "@horizon56/fonts/types";
import { DateFormat, DateTime, getTime } from "@horizon56/time";

import { translations } from "../../../../infrastructure/translations";
import iconToggleButton from "../../../buttons/icon-toggle-button.vue";
import Tooltip from "../../../tooltips/tool-tip.vue";

const props = defineProps<{
  viewDate: DateTime;
  type: "year" | "month";
  hasTitleAction?: boolean;
  min?: DateTime;
  max?: DateTime;
}>();
const emit = defineEmits<{
  (e: "update", arg: DateTime): void;
  (e: "click:title", arg: MouseEvent | KeyboardEvent): void;
}>();

const goToCurrent = () => now.value && emit("update", now.value);

const getStepIcon = computed(() => (isPrevCurrent.value ? "step_left" : "step_right") as IconName);

const now = computed(() => getTime(true));
const isPrevCurrent = computed(() => props.viewDate.isAfter(now.value, props.type));
const isNextCurrent = computed(() => props.viewDate.isBefore(now.value, props.type));

const hasPrev = computed(() => {
  if (!props.min) {
    return true;
  }
  const prev = props.viewDate.add(-1, props.type);

  return props.type === "year"
    ? props.min.get("year") <= prev.get("year")
    : props.min.get("year") * 100 + props.min.get("month") <= prev.get("year") * 100 + prev.get("month");
});

const hasNext = computed(() => {
  if (!props.max) {
    return true;
  }
  const next = props.viewDate.add(1, props.type);

  return props.type === "year"
    ? next.get("year") <= props.max.get("year")
    : props.max.get("year") * 100 + props.max.get("month") >= next.get("year") * 100 + next.get("month");
});

const title = computed(() => props.viewDate.format(props.type === "month" ? DateFormat.MONTH_YYYY : DateFormat.YYYY));
</script>

<style lang="scss" scoped>
.date-banner {
  display: grid;
  align-items: center;
  height: 40px;
  font-size: var(--app-font-size-base);
  font-weight: 500;
  background: var(--menu-hover);
  padding: 0 4px;
  grid-template-areas: "prev prevCurrent title nextCurrent next";
  grid-template-columns: 32px 52px auto 52px 32px;
  &__link,
  &__prev-btn,
  &__next-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    position: relative;
    height: var(--app-button-height-medium);
    cursor: pointer;
    border-radius: var(--app-radius-medium);
    &:hover {
      color: var(--black-90);
      background: var(--black-5);
    }
    @include focus-outline;
  }
  &__prev-btn,
  &__next-btn {
    width: var(--app-button-height-medium);
    font-size: var(--app-icon-size-medium);
    color: var(--black-28);
  }
  &__link {
    padding: 0 10px;
  }
  &__title {
    grid-area: title;
    text-align: center;
  }
  &__prev {
    grid-area: prev;
  }
  &__next {
    grid-area: next;
  }
  &__prev-btn {
    margin-right: auto;
    @include icon-after($icon-chevron_left);
  }
  &__next-btn {
    margin-left: auto;
    @include icon-after($icon-chevron_right);
  }
  &__go-to-current {
    display: flex;
    flex-flow: row nowrap;
    @mixin seperator {
      content: "";
      margin: auto 10px;
      height: 20px;
      display: inline-flex;
      border-right: 1px solid var(--black-20);
    }
    &--isPrevCurrent {
      grid-area: prevCurrent;
      &:before {
        @include seperator;
      }
    }
    &--isNextCurrent {
      grid-area: nextCurrent;
      &:after {
        @include seperator;
      }
    }
  }
}
</style>
